export default [
  // 液位
  { address: '40001', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.1 C.O.T(P)' },
  { address: '40002', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.1 C.O.T(S)' },
  { address: '40003', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.2 C.O.T(P)' },
  { address: '40004', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.2 C.O.T(S)' },
  { address: '40005', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.3 C.O.T(P)' },
  { address: '40006', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.3 C.O.T(S)' },
  { address: '40007', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.4 C.O.T(P)' },
  { address: '40008', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.4 C.O.T(S)' },
  { address: '40009', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.5 C.O.T(P)' },
  { address: '40010', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.5 C.O.T(S)' },
  { address: '40011', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.6 DE.O.T(P)' },
  { address: '40012', data: '1:100', unit: 'm', measuringRange: '0 ~ 99', name: 'NO.6 DE.O.T(S)' },
  { address: '', data: '', unit: '', measuringRange: '', name: '' },
  // 百分比
  { address: '40013', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.1 C.O.T(P)' },
  { address: '40014', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.1 C.O.T(S)' },
  { address: '40015', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.2 C.O.T(P)' },
  { address: '40016', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.2 C.O.T(S)' },
  { address: '40017', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.3 C.O.T(P)' },
  { address: '40018', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.3 C.O.T(S)' },
  { address: '40019', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.4 C.O.T(P)' },
  { address: '40020', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.4 C.O.T(S)' },
  { address: '40021', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.5 C.O.T(P)' },
  { address: '40022', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.5 C.O.T(S)' },
  { address: '40023', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.6 DE.O.T(P)' },
  { address: '40024', data: '1:1', unit: 'm', measuringRange: '0 ~ 100', name: 'NO.6 DE.O.T(S)' },
  { address: '', data: '', unit: '', measuringRange: '', name: '' },
  // 温度
  { address: '40025', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.1 C.O.T(P)' },
  { address: '40026', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.1 C.O.T(S)' },
  { address: '40027', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.2 C.O.T(P)' },
  { address: '40028', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.2 C.O.T(S)' },
  { address: '40029', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.3 C.O.T(P)' },
  { address: '40030', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.3 C.O.T(S)' },
  { address: '40031', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.4 C.O.T(P)' },
  { address: '40032', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.4 C.O.T(S)' },
  { address: '40033', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.5 C.O.T(P)' },
  { address: '40034', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.5 C.O.T(S)' },
  { address: '40035', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.6 DE.O.T(P)' },
  { address: '40036', data: '1:1', unit: '℃', measuringRange: '0 ~ 150', name: 'NO.6 DE.O.T(S)' },
  { address: '', data: '', unit: '', measuringRange: '', name: '' },
  { address: '40037', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'NO.1 H.F.O C.O.P INLET' },
  { address: '40038', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'NO.1 H.F.O C.O.P OUTLET' },
  { address: '40039', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'NO.2 H.F.O C.O.P INLET' },
  { address: '40040', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'NO.2 F.F.O C.O.P OUTLET' },
  { address: '40041', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'D.O.C.O.P INLET' },
  { address: '40042', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'D.O.C.O.P OUTLET' },
  { address: '40043', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'STRIPPING PUMP INLET' },
  { address: '40044', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'STRIPPING PUMP OUTLET' },
  { address: '40045', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'NO.1 CARGO PIPE' },
  { address: '40046', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'NO.2 CARGO PIPE' },
  { address: '40047', data: '1:100', unit: 'MPa', measuringRange: '0 ~ 1.6', name: 'NO.3 CARGO PIPE' },
  { address: '40048', data: '', unit: '', measuringRange: '', name: 'Spare' }
]
